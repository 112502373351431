/* ----------------------------------------------------------------
	Canvas: Headphones
-----------------------------------------------------------------*/


/* ----------------------------------------------------------------
	Slider
-----------------------------------------------------------------*/
.slider-element .heading-block p	{
	text-transform: uppercase;
	font-style: normal;
	letter-spacing: 5px;
	font-size: 12px;
	font-weight: 400;
	margin-bottom: 15px;
	color: rgba(255, 255, 255, 0.7);
}

.slider-element .slider-product-desc {
	position: absolute;
	top: auto;
	bottom: 0;
	left: auto;
	right: 0;
	width: 65%;
	z-index: 2;
}

.slider-element .slider-product-desc [class^='col-'] {
	background-color: #353535;
	padding: 30px;
}

.slider-element .vertical-middle .container { top: -60px; }

.play-icon {
	position: relative;
	display: block;
	font-size: 18px;
	margin: 20px 0 0 0;
	width: 60px;
	height: 60px;
	line-height: 60px;
	border-radius: 50%;
	color: #FFF !important;
	border: 2px solid #EEE;
	text-align: center;
	-webkit-transition: transform .3s ease;
	-o-transition: transform .3s ease;
	transition: transform .3s ease;
}

.play-icon i {
	position: relative;
	left: 2px;
}

.play-icon:hover {
	-webkit-transform: scale(1.1);
	-ms-transform: scale(1.1);
	-o-transform: scale(1.1);
	transform: scale(1.1);
}

.heading-block h3 {
	font-weight: 800;
	text-transform: none;
	font-size: 2.3rem;
	letter-spacing: 0;
}

.edge-underline h3,
.product:hover .product-title h3 {
	text-decoration: underline;
	text-decoration-skip: edges;
}

/* ----------------------------------------------------------------
	On Hover Changes
-----------------------------------------------------------------*/
.showcase-target-images { position: relative; }

.showcase-target {
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	-webkit-transform: scale(1, 1);
	-ms-transform: scale(1, 1);
	-o-transform: scale(1, 1);
	transform: scale(1, 1);
	-webkit-transition-duration: 0.3s;
	-moz-transition-duration: 0.3s;
	-o-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-webkit-transition-timing-function: cubic-bezier(0.57, 0.21, 0.69, 1);
	-moz-transition-timing-function: cubic-bezier(0.57, 0.21, 0.69, 1);
	-o-transition-timing-function: cubic-bezier(0.57, 0.21, 0.69, 1);
	transition-timing-function: cubic-bezier(0.57, 0.21, 0.69, 1);
}

.showcase-target.showcase-target-active {
	position: relative;
	display: block;
}

.showcase-section:hover .showcase-target {
	-webkit-transform: scale(1.05, 1.05);
	-ms-transform: scale(1.05, 1.05);
	-o-transform: scale(1.05, 1.05);
	transform: scale(1.05, 1.05);
}

.showcase-section ul li {
	width: 14px;
	height: 14px;
	border-radius: 50%;
	background-color: #000;
	cursor: pointer;
	border: 1px solid #EEE;
}

.showcase-section ul li.headphone-red { background-color: #af1e2b; }
.showcase-section ul li.headphone-black { background-color: #000; }
.showcase-section ul li.headphone-blue { background-color: darkblue; }
.showcase-section ul li.headphone-pink { background-color: pink; }
.showcase-section ul li.headphone-white { background-color: white; }

.showcase-section .badge {
	position: absolute;
	top: 0;
	text-transform: uppercase;
	font-weight: 400;
	letter-spacing: 3px;
	font-size: 11px;
	text-shadow: none;
	background-color: #222 !important;
	left: 50%;
	top: 20px;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-o-transform: translateX(-50%);
	transform: translateX(-50%);
}

/* ----------------------------------------------------------------
	Section Products
-----------------------------------------------------------------*/

.section-product {
	position: relative;
	width: 100%;
}

.section-product .section-product-image {
	width: 100%;
}

.section-product .section-product-content {
	max-width: 100%;
	position: relative;
	bottom: 0px;
	right: 0;
	background-color: #FFF;
	padding: 20px;
	z-index: 1;
}

.section-product .section-product-content h3 { font-weight: 800; }

.section-product.alt .section-product-image,
.section-product.alt .section-product-content { float: none; }

.section-product .section-product-price {
	font-size: 1.5rem;
	font-weight: 700;
}

.explore-text-overlay {
	opacity: 0;
	position: absolute;
	display: block;
	top: 0;
	left: 0;
	width: calc(100% - 30px);
	height: 100%;
	z-index: 1;
	background-color: rgba(0,0,0,0.6);
	margin-left: 15px;
	-webkit-transition: all .4s ease ;
	-o-transition: all .4s ease ;
	transition: all .4s ease ;
}

.text-overlay-wrap:hover .explore-text-overlay { opacity: 1; }

.section-contact h3 {
	font-size: 1.4rem;
	font-weight: 700;
}

.section-contact > div {
	padding: 30px 20px;
	border: 1px solid #EEE;
}

.social-icon.si-mini {
	width: 28px;
	height: 28px;
	font-size: 12px;
	line-height: 27px !important;
}

.social-icon.si-mini:hover i:first-child { margin-top: -27px; }

@media (min-width: 1199px) {

	.menu-link {
		font-size: 15px;
		letter-spacing: 0;
		text-transform: none;
	}

	/* Hover Effects style-7 */
	.menu-link div {
		position: relative;
		overflow: hidden;
	}

	.menu-link div::before,
	.menu-link div::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(255, 255, 255, 0.4);
		transform: translate3d(-100%,0,0) translate3d(-1px,0,0);
		transition: transform 0.7s;
		transition-timing-function: cubic-bezier(0.7,0,0.3,1);
		z-index: -1;
	}

	.menu-item.current > .menu-link div::before,
	.menu-link:hover div::before { transform: translate3d(100%,0,0) translate3d(1px,0,0); }

	.menu-item.current > .menu-link div::after,
	.menu-link:hover div::after { transform: translate3d(0,5px,0); }

	.menu-link div::after {
		top: calc(50%);
		height: 4px;
	}

	.product {
		padding: 65px;
		border-right: 1px solid #EEE;
	}

	.slider-element .heading-block h2 {
		font-size: 3.1rem;
		line-height: 1.35;
		text-transform: none;
		letter-spacing: 0;
		font-weight: 700;
	}

	.slider-element .slider-product-desc [class^='col-'] { padding: 50px; }

	.play-icon { margin: 0 auto; }

	.section-product .section-product-image {
		position: relative;
		width: 85%;
		background: #FFF;
	}

	.section-product .section-product-content {
		max-width: 400px;
		position: absolute;
		bottom: 30px;
		right: 0;
		padding: 30px;
	}

	.section-product.alt .section-product-image { float: right; }

	.section-product.alt .section-product-content {
		left: 0;
		right: auto;
	}

}

@media (max-width: 991px) {
	.slider-element .vertical-middle .container { top: 0; }
}

.inline-YTPlayer { z-index: 1; }

#ytb-video-button.play-icon {
	position: absolute;
	left: auto;
	bottom: 10px;
	right: 10px;
	width: 42px;
	height: 42px;
	line-height: 39px;
	font-size: 20px;
	text-align: center;
	border-width: 2px;
	z-index: 2;
}

#ytb-video-button.play-icon i:nth-of-type(1) {
	position: relative;
	left: 1px;
}

#ytb-video-button.play-icon i:nth-of-type(2),
#ytb-video-button.play-icon.video-played i:nth-of-type(1) { display: none; }

#ytb-video-button.play-icon.video-played i:nth-of-type(2) { display: block; }







.grid {
	position: relative;
}

.description { display: none; }
.grid-item-current { opacity: 0 !important; }
.img-wrap { display: block; }

.img-wrap img {
	display: block;
	max-width: 100%;
}

.img-grayscale img {
	-webkit-filter: grayscale(90%);
	-webkit-transition: .4s ease-in-out;
	-moz-filter: grayscale(90%);
	-moz-transition: .4s ease-in-out;
	-o-filter: grayscale(90%);
	-o-transition: .4s ease-in-out;
}

.img-grayscale:hover img {
  -webkit-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  -o-filter: grayscale(0%);
}

.preview {
	position: fixed;
	z-index: 1000;
	top: 0;
	left: 0;
	display: -ms-flex;
	display: -webkit-flex;
	display: flex;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-ms-flex-line-pack: center;
	-webkit-align-content: center;
	align-content: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
	width: 50%;
	height: 100%;
	pointer-events: none;
}

.preview::before {
	content: '';
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	background: #1f1d1d;
	-webkit-transition: opacity 0.6s;
	transition: opacity 0.6s;
}

.preview-open { pointer-events: auto; }

.preview-open::before { opacity: 1; }

.clone {
	position: fixed;
	z-index: 110;
	-webkit-transition: -webkit-transform 0.5s;
	transition: transform 0.5s;
	-webkit-backface-visibility: hidden;
}

.original {
	position: relative;
	z-index: 120;
	display: block;
	object-fit: contain;
	-webkit-transition: opacity 0.2s;
	transition: opacity 0.2s;
	-webkit-backface-visibility: hidden;
}

.preview-open .animate {
	/* open */
	-webkit-transition: -webkit-transform 0.6s, opacity 0.2s;
	transition: transform 0.6s, opacity 0.2s;
}

.animate {
	/* close */
	-webkit-transition: -webkit-transform 0.3s, opacity 0.2s;
	transition: transform 0.3s, opacity 0.2s;
}

.description { color: #fff; }
.js .description-grid { display: none; }

.description-preview {
	font-size: 2em;
	position: absolute;
	z-index: 140;
	width: 100%;
	left: 100%;
	top: 0;
	height: 100%;
	padding: 0 1em;
	display: -ms-flex;
	display: -webkit-flex;
	display: flex;
	-ms-flex-direction: column;
	-webkit-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-ms-flex-align: start;
	-webkit-align-items: flex-start;
	align-items: flex-start;
	opacity: 0;
	-webkit-transition: opacity 1s, -webkit-transform 1s;
	transition: opacity 1s, transform 1s;
	-webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
	transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
	-webkit-transform: translate3d(0, 30px, 0);
	transform: translate3d(0, 30px, 0);
}

.preview-open .description-preview {
	opacity: 1;
	-webkit-transition-delay: 0.2s;
	transition-delay: 0.2s;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.description-preview h3 {
	font-weight: 400;
	margin: 0;
	color: #333;
	text-transform: uppercase;
	letter-spacing: 2px;
}

.dark .description-preview h3 { color: #FFF; }

.description-preview p {
	font-size: 17px;
	font-weight: 300;
	color: #555;
	max-width: 100%;
	margin-top: 20px;
}

.dark .description-preview p { color: #CCC; }

.description-preview p span {
	color: #888;
	font-size: 14px;
	display: block;
	font-weight: 300;
	padding: 10px 0 0 0;
}

.dark .description-preview p span { color: #AAA; }

/* Details */
.details { max-width: 100%; } /* IE 10-11 bug flexbox */

.details ul {
	line-height: 1;
	position: relative;
	margin: 0;
	padding: 0;
	list-style: none;
}

.details ul li {
	font-size: 0.5em;
	position: relative;
	display: inline-block;
	margin: 0 1em 0 0;
	padding: 0.15em 0;
	white-space: nowrap;
	opacity: 0;
	color: #9d9d9d;
	-webkit-transition: -webkit-transform 1s, opacity 1s;
	transition: transform 1s, opacity 1s;
	-webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
	transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
	-webkit-transform: translate3d(0, 20px, 0);
	transform: translate3d(0, 20px, 0);
}

.preview-open .details ul li {
	opacity: 1;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.preview-open .details ul li:nth-child(1) {
	-webkit-transition-delay: 0.2s;
	transition-delay: 0.2s;
}

.preview-open .details ul li:nth-child(2) {
	-webkit-transition-delay: 0.3s;
	transition-delay: 0.3s;
}

.preview-open .details ul li:nth-child(3) {
	-webkit-transition-delay: 0.4s;
	transition-delay: 0.4s;
}

.preview-open .details ul li:nth-child(4) {
	-webkit-transition-delay: 0.5s;
	transition-delay: 0.5s;
}

.preview-open .details ul li:nth-child(5) {
	-webkit-transition-delay: 0.6s;
	transition-delay: 0.6s;
}

.details ul li:first-child {
	font-weight: bold;
	color: #909090;
}

.icon + span {
	margin-left: 5px;
	vertical-align: middle;
}

.details .icon {
	margin-right: 5px;
	color: #c85e51;
}


/* Close button */

.action {
	font-size: 40px;
	margin: 0;
	padding: 0;
	cursor: pointer;
	vertical-align: top;
	color: #c85e51;
	border: none;
	background: none;
}

.action:hover,
.action:focus {
	color: #c85e51;
	outline: none;
}

.action-close {
	position: fixed;
	z-index: 150;
	top: 30px;
	right: 30px;
	padding: 0;
	opacity: 0;
	-webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
	transition: opacity 0.3s, transform 0.3s;
	-webkit-transform: scale3d(0.6, 0.6, 1);
	transform: scale3d(0.6, 0.6, 1);
}

.preview-image-loaded .action-close {
	opacity: 1;
	-webkit-transform: scale3d(1, 1, 1);
	transform: scale3d(1, 1, 1);
}

.text-hidden {
	position: absolute;
	display: block;
	overflow: hidden;
	width: 0;
	height: 0;
	color: transparent;
}

@media screen and (max-width: 40em) {
	.description-preview h3 { font-size: 0.5em; }

	.description-preview p,
	.details { display: none; }
}
